.banner {

}


// Sizes
.banner--large {

    padding-top: rem(72);
    padding-bottom: rem(56);

    @include large {
        padding-top: rem(96);
        padding-bottom: rem(64);
    }

    @include large {
        padding-top: rem(140);
        padding-bottom: rem(80);
    }
}

.banner--small {
    padding-top: rem(48);
    padding-bottom: rem(48);

    @include large {
        padding-top: rem(56);
        padding-bottom: rem(48);
    }

    @include large {
        padding-top: rem(72);
        padding-bottom: rem(56);
    }
}

.banner--bleed {
    max-width: none;
    padding-left: 0;
    padding-right: 0;

}

// Themes
.banner_primary, .banner_promo {
    background-size: cover;
    background-position-y: 100%;
    color: $color-secondary-inverse;

    .banner__heading {

        &:after {
            border-bottom-color: $color-primary;
        }
    }
}

    .banner_primary {
        background-image: url("/static/images/hero-purple-mobile-min.png");

        @include medium {
            background-image: url("/static/images/hero-purple-min.png");
        }
    }

    // Purple tear
    .banner_promo {
        background-image: url("/static/images/hero-purple-reversed-min.png");
        background-position-y: 0%;
    }

    // Grey
    .banner_secondary {
        background-color: $color-light-grey;
        background-image: url("/static/images/hero-grey-min.png");

        .banner__heading {
            &:after {
                // border-bottom-color: $color-secondary;
                background-color: $color-secondary;
            }
        }
    }

    // Home page
    .banner_home {
        position: relative;
        background-color: black;

        @include large {
            background-color: transparent;
            height: 75vh;
            min-height: 580px;
            max-height: 950px;
        }
    }

    .banner__video {
        filter: grayscale(100%) contrast(100%) brightness(60%);
        width: 100%;

        @include large {
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 0;
        }
    }

$heading-border-height: 14px;
$heading-border-height--large: 20px;


// Was using .tag for this element but too many overrides. Made it
// its own thing, but might revert. Made to be the same as normal
// banner underlines but includes text.
.banner__heading_text_underline {
    background-color: $color-secondary;
    color: $color-primary-inverse;
    display: block;
    text-align: right;
    padding-right: rem(8);
    line-height: $heading-border-height;
    height: $heading-border-height;
    font-weight: $font-weight-bold;
    letter-spacing: rem(0.7px);
    font-size: rem(11);

    @include large {
        font-size: rem(13);
        line-height: $heading-border-height--large;
        height: $heading-border-height--large;
    }
}

// Banner elements
.banner__heading {
    display: inline-block;
    position: relative;
    margin-bottom: rem(32);

    @include medium {
        margin-bottom: rem(56);
    }

    @include large {
        margin-bottom: rem(72);
    }

    // Bottom border
    span {
        position: relative;
        display: inline-block;
        padding-bottom: 0.3em;
    }

    &:after {
        content: "";
        display: block;
        height: rem($heading-border-height);
        width: 100%;
        background-color: $color-primary;
        // border-bottom-style: solid;
        // border-bottom-color: $color-primary;
        // border-bottom-width: rem($heading-border-height);

        @include large {
            border-bottom-width: rem($heading-border-height--large);
        }
    }

    &:after,
    .banner__heading_text_underline {
        // position: absolute;
        // bottom: rem(-32px);
        // width: 100%;
    }

    &.u-muted--alt {
        color: $color-primary;
        opacity: 0.3;
    }

    .breadcrumbs + & {
        margin-top: 0.5em;
    }

    &.banner__heading_has-content {
        margin-bottom: rem(32px);
    }
}



.banner__heading_borderless {
    &:after {
        content: none;
    }
}

.banner__subheading {
    @include relative-font(
        $font-size: 28px,
        $line-height: 29px
    );
}


.banner_promotions {
    padding-top: 6rem;
    padding-bottom: 3rem;
}
