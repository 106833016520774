// Elements
// -----------------------------------------------------------------------------

h1, h2, h3,
.h1, .h2, .h3 {
    font-weight: $font-weight-black;
    text-transform: uppercase;
}

h1, .h1 {
    font-size: rem(36px);
    line-height: 0.9;
    letter-spacing: rem(-0.8px);

    @include large {
        font-size: rem(52px);
    }

    @include xlarge {
        font-size: rem(64px);
    }
}

h2, .h2 {
    font-size: rem(24px);
    line-height: 1;

    @include large {
        font-size: rem(32px);
    }
}

h3, .h3 {
    font-size: rem(21px);
    line-height: 1.14;
    letter-spacing: rem(-0.3px);
}

h4, .h4 {
    font-size: rem(22px);
    line-height: 1.3;
    font-weight: $font-weight-xbold;
    letter-spacing: rem(-0.2px);
}

h5, .h5 {
    font-size: rem(18px);
    line-height: 1.8;
    font-weight: $font-weight-bold;
}

h6, .h6 {
    font-size: rem(13px);
    line-height: 1.5;
    font-weight: $font-weight-bold;
    letter-spacing: rem(0.5px);
    text-transform: uppercase;

    @include large {
        font-size: rem(14px);
    }
}


// Paragraphs
// -----------------------------------------------------------------------------

p {
    margin-top: 1em;
    line-height: 1.8;
    font-size: rem(16);

    @include large {
        font-size: rem(18);
    }
}

.u-xlarge,
.intro {
    font-weight: $font-weight-bold;
    font-size: rem(18);
    line-height: 1.5;

    @include large {
        font-size: rem(20);
    }

    @include xlarge {
        font-size: rem(22);
    }
}

.u-large {
    font-size: rem(18);

    @include large {
        font-size: rem(20);
    }
}

.u-small, small {
    font-size: rem(16);
}

.u-xsmall {
    font-size: rem(14);
}

strong {
    font-weight: $font-weight-bold;
}




// Relationship spacing
// -----------------------------------------------------------------------------

h2, .h2 {
    p + & {
        margin-top: 2em;
    }

    @include large {
        p + & {
            margin-top: 3em;
        }
    }
}


h3, .h3 {
    @include large {
        h2 + & {
            margin-top: 2em;
        }

        p + & {
            margin-top: 1.5em;
        }
    }
}


h4 {

}


h5, .h5 {
    h2 + & {
        margin-top: 1.5em;
    }

    h4 + & {
        margin-top: 0.5em;
    }

    p + & {
        margin-top: 2em;
    }
}


h6 {
}


p {
    h2 + & {
        margin-top: 1em;
    }

    h6 + & {
        margin-top: 0.5em;
    }

    @include large {
        h2 + & {
            margin-top: 2em;
        }
    }

    // .intro + & {
    //     @include medium {
    //         margin-top: 1.5em;
    // }
}
