@import '../../framework_deprecated/settings/index';

$font-family-primary: "aktiv-grotesk", sans-serif;

$transparency: 0.6;

$color-site-black: #000000;
$color-site-white: #FFFFFF;
$color-site-off-white: #F3F3F3;
$color-light-grey: #E5E5E5;
$color-grey: #D9D9D9;
$color-dark-grey: #555555;
$color-site-purple: #7C01FF;
$color-site-dark-purple: #5700B3;
$color-site-pink: #E80E4D;

$color-grey-transparent: rgba($color-grey, $transparency);

$color-primary: $color-site-black;
$color-primary-inverse: $color-site-white;

$color-secondary: $color-site-purple;
$color-secondary-hover: $color-site-dark-purple;
$color-secondary-inverse: $color-site-white;
$color-secondary-inverse-hover: $color-site-off-white;

$color-tertiary: $color-site-pink;
$color-tertiary-inverse: $color-site-white;
